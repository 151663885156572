<template>
  <tour :steps="tourSteps">
  <div class="content-wrapper">
    <section v-if="ExistsCompany">
      <LinkFirstCompanyExists
        :userEmail="emailUsuarioEmpresa"
        :cnpjEmpresa="form.document"
        :tipoDocumento="tipoDocumentoEmpresa"
        @linkCompany="handleLinkAnotherCompany"
      />
    </section>
    <div id="cadastrar-empresa">
      <b-card v-if="!ExistsCompany">
        <h2>Vincular uma empresa</h2>
        <p class="mb-0" v-if="firsCompany">
          Para estar apto a usar a plataforma VacinaSESI você precisa estar
          vinculado a uma empresa.
        </p>
        <p>Insira as informações necessárias para continuar.</p>
  
        <b-alert class="p-1" :show="form.errorApi.error ? true : false">
          {{ form.errorApi.error }}
        </b-alert>
  
        <validation-observer ref="linkFirstCompanyRules">
          <b-form>
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  label="CNPJ ou CPF*"
                  label-for="cnpj"
                  label-class="font_size_label_empresa"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cnpj"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="description-input"
                      v-mask="getMask"
                      v-model="form.document"
                      @input="validateDocument"
                      placeholder="00.000.000/0000-00"
                    />
                    <small v-if="errors[0]" class="text-danger d-block">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.dadosPrincipais.documento"
                      class="text-danger"
                    >
                      {{ form.errorApi.dadosPrincipais.documento }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <section v-if="cnpjDocument">
              <b-row>
                <b-col lg="6" md="6" sm="12" class="pr-0 mt-2">
                  <b-form-group
                    label="Nome Fantasia*"
                    label-for="fantasyName"
                    label-class="font_size_label_empresa"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fantasyName"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        id="description-input"
                        v-model="form.fantasyName"
                        placeholder="Nome fantasia da empresa"
                      />
                      <small v-if="errors[0]" class="text-danger d-block">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small
                        v-if="form.errorApi.dadosPrincipais.nome_fantasia"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.nome_fantasia }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" class="pr-0 mt-2">
                  <b-form-group
                    label="Razão Social*"
                    label-for="companyName"
                    label-class="font_size_label_empresa"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="companyName"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        id="description-input"
                        v-model="form.companyName"
                        placeholder="Razão social da empresa"
                      />
                      <small v-if="errors[0]" class="text-danger d-block">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small
                        v-if="form.errorApi.dadosPrincipais.razao_social"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.razao_social }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </section>
            <section v-if="cpfDocument">
              <b-row>
                <b-col xl="4">
                  <b-form-group
                    label="Nome Completo*"
                    label-for="name-input"
                    label-class="font_size_label_empresa"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name-input"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        id="name-input"
                        v-model="form.name"
                        placeholder="Nome completo"
                        v-mask="'X'.repeat(255)"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small
                        v-if="form.errorApi.dadosPrincipais.nome"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.nome }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col xl="4">
                  <b-form-group
                    label="Inscrição estadual*"
                    label-for="stateRegistration-input"
                    label-class="font_size_label_empresa"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="stateRegistration-input"
                      rules="required|length:11"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        id="inscricao-estadual-input"
                        v-model="form.stateRegistration"
                        placeholder="Inscrição estadual da empresa"
                        v-mask="'###.###.###'"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small
                        v-if="form.errorApi.dadosPrincipais.inscricao_estadual"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.inscricao_estadual }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col xl="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Sexo"
                    rules="required"
                  >
                    <b-form-group
                      label="Sexo*"
                      label-for="gender"
                      label-class="font_size_label_empresa"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="status"
                        v-model="form.inputGender"
                        :options="['Masculino', 'Feminino']"
                        variant="custom"
                        item-text="description"
                        item-value="value"
                        placeholder="Selecione o sexo"
                        label="description"
                      />
  
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small v-if="form.errorApi.genero" class="text-danger">
                        {{ form.errorApi.genero }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col xl="4">
                  <validation-provider
                    #default="{ errors }"
                    name="DataNascimento"
                    rules="required|length:10"
                  >
                    <b-form-group
                      label="Data de Nascimento*"
                      label-for="birthdate-input"
                      label-class="font_size_label_empresa"
                    >
                      <b-input-group>
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          class="form-control"
                          id="birthdate-input"
                          v-model="inputBirthDate"
                          v-mask="'##/##/####'"
                          placeholder="00/00/0000"
                          autocomplete="off"
                          @input="setBirthDateInput"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            class="form-control d-flex align-items-center"
                            locale="pt-BR"
                            button-only
                            right
                            :hide-header="true"
                            v-model="form.birthDate"
                            label-help="Selecione a data de nascimento"
                            button-variant="custom-blue"
                            clearable
                            @input="setBirthDatePicker"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small
                        v-if="form.errorApi.dadosPrincipais.data_nascimento"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.data_nascimento }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
  
                <b-col xl="4">
                  <b-form-group
                    label="Nome da mãe"
                    label-class="font_size_label_empresa nome_mae"
                  >
                    <validation-provider #default="{ errors }" name="nome_mae">
                      <b-form-input
                        :state="
                          errors.length > 0 || form.errorApi.nome_mae
                            ? false
                            : null
                        "
                        id="nome_mae-input"
                        v-model="form.nomeMae"
                        placeholder="Nome da mãe"
                      />
                      <small
                        v-if="form.errorApi.dadosPrincipais.nome_mae"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.nome_mae }}
                      </small>
                      <small
                        v-if="form.errorApi.dadosPrincipais.error"
                        class="text-danger"
                      >
                        {{ form.errorApi.dadosPrincipais.error }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
  
                <b-col xl="4">
                  <NacionalidadeField
                    :idNacionalidade="form.idNacionalidade"
                    @updateNacionalidade="updateNacionalidade"
                    :errorApiIdNacionalidade="
                      form.errorApi.dadosPrincipais.id_nacionalidade
                    "
                    customClass="link-first-company"
                  />
                </b-col>
              </b-row>
            </section>
          </b-form>
        </validation-observer>
        <b-button
          id="save-first-company"
          class="mr-2 mt-4"
          variant="custom-blue"
          @click.prevent="validaCompanyData()"
          :disabled="saving"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Continuar</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        </b-button>
      </b-card>
    </div>
  </div>
</tour>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import moment from "moment";
import handleErrorsService from "./Services/HandleErrors";
import LinkFirstCompanyExists from "@/views/custom-pages/empresa/Link-first-company-exists.vue";
import vSelect from "vue-select";
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";
import { addEmpresaToList } from "@core/utils/store/setStore";
import tourMixin from "@/mixins/tourMixin";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    LinkFirstCompanyExists,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    NacionalidadeField
  },
  mixins: [tourMixin],
  data() {
    return {
      required,
      ExistsCompany: false,
      emailUsuarioEmpresa: "",
      tipoDocumentoEmpresa: "",
      companies: this.$store.state.sessions.userData.empresas,
      form: {
        document: "",
        fantasyName: null,
        companyName: null,
        name: null,
        stateRegistration: null,
        birthDate: null,
        inputGender: null,
        nomeMae: null,
        idNacionalidade: null,
        errorApi: {
          empresaTipo: null,
          dadosPrincipais: {
            documento: null,
            nome_fantasia: null,
            razao_social: null,
            nome: null,
            inscricao_estadual: null,
            data_nascimento: null,
            gender: null,
            nome_mae: null,
            id_nacionalidade: "",
            error: "",
          },
        },
      },
      saving: false,
      cpfDocument: false,
      cnpjDocument: true,
      firsCompany: localStorage.getItem("firsCompanyRegister"),
      inputBirthDate: null,
    };
  },

  computed: {
    getMask() {
      const rawValue = this.form.document.replace(/\D/g, "");
      return rawValue.length <= 11 ? "###.###.###-##" : "##.###.###/####-##";
    }
  },

  methods: {
    validaCompanyData() {
      this.$refs.linkFirstCompanyRules.validate().then((success) => {
        if (success) {
          this.checkCompanyData();
        }
      });
    },
    validateDocument() {
      const rawValue = this.form.document.replace(/\D/g, "");
      
      if (rawValue.length <= 11) {
        this.showCpf();
      } else if (rawValue.length >= 12) {
        this.showCnpj();
      }
    },

    showCpf() {
      this.cnpjDocument = false;
      this.cpfDocument = true;
    },

    showCnpj() {
      this.cnpjDocument = true;
      this.cpfDocument = false;
    },

    checkCompanyData() {
      this.saving = true;

            const parameters = {
                documento: this.form.document.match(/\d/g).join(""),
                empresaTipo: this.cnpjDocument ? 'cnpj' : 'cpf'
            };
            this.$http.post(this.$api.empresaVerificaVinculo(), parameters).then(({ data }) => {
                
                if(!data['empresa']) {
                    this.registerCompany();
                } else {
                    if(data['usuario']){
                        this.emailUsuarioEmpresa = data['usuario']['email'];
                        this.tipoDocumentoEmpresa = this.cnpjDocument ? 'cnpj' : 'cpf';
                        this.ExistsCompany = true;
                    }else{
                        addEmpresaToList(data['empresa'])

                        this.$router.push({ 
                            name: 'empresa-edit',
                            params: {
                                idEmpresa: data['empresa']['id_empresa'],
                            }
                        });
                    }
                }
                this.saving = false;
            }).catch((error) => {
                const data = error.response.data;
                this.form.errorApi.dadosPrincipais.documento = data.errors.documento ? data.errors.documento[0] : null;
                this.saving = false;
            });
        },
        prepareDataToSave(){        
                let dados = {
                empresaTipo: this.cnpjDocument ? 'cnpj' : 'cpf',
                dadosPrincipais: {
                    documento: this.form.document.match(/\d/g).join(""),
                    nome_fantasia: this.form.fantasyName,
                    razao_social: this.form.companyName,
                    nome: this.form.name,
                    inscricao_estadual: this.form.stateRegistration,
                    data_nascimento: this.form.birthDate,
                    genero: this.form.inputGender,
                    nome_mae: this.form.nomeMae,
                    id_nacionalidade: this.form.idNacionalidade,
                }
                };
                if(this.cnpjDocument ){
                  dados['faturamento'] = {
                    cnpj_faturamento: this.form.document.match(/\d/g).join("")
                  }
                }

      return dados;
    },
    registerCompany() {
      const parameters = this.prepareDataToSave();

      this.$http
        .post(this.$api.saveAndGetEmpresa(), parameters)
        .then(({ data }) => {
          localStorage.removeItem("firsCompanyRegister");

          this.saving = false;

          this.$store.commit("sessions/ADD_USER_COMPANY", data);

          this.$router.push({
            name: "empresa-edit",
            params: {
              idEmpresa: data.empresa.id_empresa,
            },
          });
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosEmpresa(data, this.form.errorApi);

          this.saving = false;
        });
    },

    setBirthDatePicker() {
      this.inputBirthDate = moment(this.form.birthDate).format("DD/MM/YYYY");
    },
    setBirthDateInput() {
      this.form.birthDate = moment(this.inputBirthDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
    },

    handleLinkAnotherCompany() {
      this.ExistsCompany = false;
      this.saving = false;

      this.form.cnpj = "";
      this.form.fantasyName = null;
      this.form.companyName = null;
    },

    updateNacionalidade(id_nacionalidade) {
      this.form.idNacionalidade = id_nacionalidade;
    },
  },
};
</script>

<style>
.nome_mae {
  margin-bottom: 0.2857rem;
  padding: 0;
}
</style>